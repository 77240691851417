import type { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useMobileCheck } from 'src/hooks/use-mobile-check'
import { MainLayout } from '../components/main-layout'
import { getTenantDocsAppName } from 'src/lib/tenant'

const Home: NextPage = () => {
  const router = useRouter()
  const query = router.query

  useEffect(() => {
    router.push("/authentication/login?returnUrl=%2Fteacher%2Fprints")
  }, [query])

  return (
    <>
      <Head>
        <title>{getTenantDocsAppName()}</title>
      </Head>
    </>
  )
}

Home.getLayout = (page) => <MainLayout>{page}</MainLayout>

export default Home
